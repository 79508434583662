<template>
  <v-sheet class="pa-2">
    <v-card
      v-show="show === 'info'"
      class="ma-auto pa-1"
      max-width="500"
    >
      <v-card-title>登録情報</v-card-title>
      <v-divider />
      <v-card-text class="pl-3 pb-0">
        名前
      </v-card-text>
      <v-row class="justify-space-between mx-1 mb-2 px-2 align-center">
        <p class="ma-0">
          {{ userName }}
        </p>
      </v-row>
      <v-divider />
      <v-card-text class="pl-3 pb-0">
        メールアドレス
      </v-card-text>
      <v-row class="justify-space-between mx-1 mb-2 px-2 align-center">
        <p class="ma-0">
          {{ email }}
        </p>
      </v-row>
      <template v-if="$cpaMode">
        <v-divider />
        <v-card-text class="pl-3 pb-0">
          パスワード
        </v-card-text>
        <v-row class="justify-space-between mx-1 mb-2 px-2 align-center">
          <p class="ma-0">
            **********
          </p>
          <v-btn @click="sendChangePasswordMail">
            パスワード変更
          </v-btn>
        </v-row>
      </template>
      <template v-if="$cpaMode">
        <v-divider />
        <v-card-text class="pl-3 pb-0">
          自動ログアウト（ログアウト時初期設定に戻ります）
        </v-card-text>
        <v-row class="justify-space-between mx-1 mb-2 px-2 align-center">
          <v-col
            class="pt-0"
            cols="2"
          >
            <v-switch
              class="pa-0"
              :input-value="autoLogoutFlg"
              color="info"
              @change="toggleAutoLogoutFlg"
            />
          </v-col>
          <v-col class="pt-0">
            <v-text-field
              v-show="autoLogoutFlg"
              :value="expireDurationMinutes"
              type="number"
              suffix="使用停止後"
              prefix="分後にログアウト"
              reverse="reverse"
              @input="updateExpireMinutes"
            />
          </v-col>
        </v-row>
      </template>
      <v-snackbar
        v-model="showMailSendSuccess"
        top="top"
        color="success"
      >
        {{ email }} にパスワード変更メールを送信しました。
      </v-snackbar>
      <v-snackbar
        v-model="showMailSendFailed"
        top="top"
        color="error"
      >
        メールの送信に失敗しました。
      </v-snackbar>
    </v-card>
  </v-sheet>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: "Account",
  data() {
    return {
      show: 'info',
      showMailSendSuccess: false,
      showMailSendFailed: false,
    }
  },
  computed: {
    ...mapGetters(['userName', 'email', 'autoLogoutFlg', 'expireDurationMinutes']),
  },
  methods: {
    paginate(action) {
      this.$router.push({ name: action })
    },
    updateExpireMinutes(e) {
      if (e <= 0) return;
      this.$store.dispatch('updateExpiredDurationMinutes', e)
      this.$store.dispatch('updateExpiredAt')
    },
    toggleAutoLogoutFlg() {
      this.$store.dispatch('updateAutoLogoutFlg', !this.autoLogoutFlg)
    },
    sendChangePasswordMail() {
      const isConfirmed = confirm('パスワードを変更するためにメールを送信します。よろしいですか？')
      if (!isConfirmed) return;

      const success = this.$auth.changePassword(this.email);
      if (success) {
        this.showMailSendSuccess = true
      } else {
        this.showMailSendFailed = true
      }
    },
  },
}
</script>

<style scoped>

</style>
